export enum Component {
  Part1 = 'Part1',
  Part2 = 'Part2',
  Part3 = 'Part3',
}

export interface ManufacturingOptions {
  component: Component;
  title: string;
  text1: string;
  text2: string;
  dot1StatusQueryName: string | undefined;
  dot1Title: string;
  dot1Description: string;
  dot2StatusQueryName: string | undefined;
  dot2Title: string;
  dot2Description: string;
  dot3StatusQueryName: string | undefined;
  dot3Title: string;
  dot3Description: string;
  dot4StatusQueryName: string | undefined;
  dot4Title: string;
  dot4Description: string;
  dot5StatusQueryName: string | undefined;
  dot5Title: string;
  dot5Description: string;
  dot6StatusQueryName: string | undefined;
  dot6Title: string;
  dot6Description: string;
  dot7StatusQueryName: string | undefined;
  dot7Title: string;
  dot7Description: string;
  dot8StatusQueryName: string | undefined;
  dot8Title: string;
  dot8Description: string;
}
