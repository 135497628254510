import React from 'react';
import { PanelProps } from '@grafana/data';
import { stylesFactory, VerticalGroup, HorizontalGroup } from '@grafana/ui';
import { css, cx } from '@emotion/css';

import { Component, ManufacturingOptions } from './types';
import { DotState, dotStateForNumber, getNumberFromQuery, StatusDot } from 'uef-common';

const add1Image = require('./img/Gehause.png');
const add2Image = require('./img/Einpresshilfe.png');
const add3Image = require('./img/Verschleissteil.png');

type StatusDotConfig = { title: string; description: string; dotState: DotState };

interface Props extends PanelProps<ManufacturingOptions> {}

export const ManufacturingPanel: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = getStyles();

  const image = React.useMemo(
    () =>
      options.component === Component.Part1 ? add1Image : options.component === Component.Part2 ? add2Image : add3Image,
    [options.component]
  );

  const dotStates: StatusDotConfig[] = React.useMemo(() => {
    const newDots: StatusDotConfig[] = [];

    const dot1Value = getNumberFromQuery(data, options.dot1StatusQueryName);
    newDots.push({
      title: options.dot1Title,
      description: options.dot1Description,
      dotState: dotStateForNumber(dot1Value),
    });

    const dot2Value = getNumberFromQuery(data, options.dot2StatusQueryName);
    newDots.push({
      title: options.dot2Title,
      description: options.dot2Description,
      dotState: dotStateForNumber(dot2Value),
    });

    const dot3Value = getNumberFromQuery(data, options.dot3StatusQueryName);
    newDots.push({
      title: options.dot3Title,
      description: options.dot3Description,
      dotState: dotStateForNumber(dot3Value),
    });

    const dot4Value = getNumberFromQuery(data, options.dot4StatusQueryName);
    newDots.push({
      title: options.dot4Title,
      description: options.dot4Description,
      dotState: dotStateForNumber(dot4Value),
    });

    const dot5Value = getNumberFromQuery(data, options.dot5StatusQueryName);
    newDots.push({
      title: options.dot5Title,
      description: options.dot5Description,
      dotState: dotStateForNumber(dot5Value),
    });

    const dot6Value = getNumberFromQuery(data, options.dot6StatusQueryName);
    newDots.push({
      title: options.dot6Title,
      description: options.dot6Description,
      dotState: dotStateForNumber(dot6Value),
    });

    const dot7Value = getNumberFromQuery(data, options.dot7StatusQueryName);
    newDots.push({
      title: options.dot7Title,
      description: options.dot7Description,
      dotState: dotStateForNumber(dot7Value),
    });

    const dot8Value = getNumberFromQuery(data, options.dot8StatusQueryName);
    newDots.push({
      title: options.dot8Title,
      description: options.dot8Description,
      dotState: dotStateForNumber(dot8Value),
    });

    return newDots;
  }, [options, data]);

  const ManufacturingImage = ({ image }: { image: string }) => {
    return (
      <img
        className={cx(css`
            max-height: 300px};
          `)}
        src={image}
      />
    );
  };

  const StatusBox = ({
    title,
    dot1Title,
    dot2Title,
    statusDotConfig1,
    statusDotConfig2,
  }: {
    title: string;
    dot1Title: string;
    dot2Title: string;
    statusDotConfig1: StatusDotConfig;
    statusDotConfig2: StatusDotConfig;
  }) => {
    return (
      <div className={cx(styles.box)}>
        <VerticalGroup align="center">
          <h2>{title}</h2>
          <div className={cx(styles.statusBox)}>
            <StatusDot
              dotState={statusDotConfig1.dotState}
              infoTitle={statusDotConfig1.title}
              infoMessage={statusDotConfig1.description}
              useModal
            />
          </div>
          <p>{dot1Title}</p>
          <div className={cx(styles.statusBox)}>
            <StatusDot
              dotState={statusDotConfig2.dotState}
              infoTitle={statusDotConfig2.title}
              infoMessage={statusDotConfig2.description}
              useModal
            />
          </div>
          <p>{dot2Title}</p>
        </VerticalGroup>
      </div>
    );
  };

  return (
    <VerticalGroup>
      <h1 className={cx(styles.title)}>{options.title}</h1>
      <HorizontalGroup spacing="lg" justify="center" align="center">
        <ManufacturingImage image={image} />
      </HorizontalGroup>
      <div
        className={cx(css`
          margin-top: 90px;
        `)}
      />
      <HorizontalGroup spacing="lg" justify="center" align="center" wrap={true}>
        <div className={cx(styles.box)}>
          <VerticalGroup align="center">
            <h2>Entwicklung</h2>
            <p className={cx(styles.devText)}>{options.text1}</p>
            <p className={cx(styles.devText)}>{options.text2}</p>
          </VerticalGroup>
        </div>
        <StatusBox
          title="Pre"
          dot1Title="Statusbeschreibung"
          dot2Title="Statusbeschreibung"
          statusDotConfig1={dotStates[0]}
          statusDotConfig2={dotStates[1]}
        />
        <StatusBox
          title="In"
          dot1Title="Statusbeschreibung"
          dot2Title="Statusbeschreibung"
          statusDotConfig1={dotStates[2]}
          statusDotConfig2={dotStates[3]}
        />
        <StatusBox
          title="Post"
          dot1Title="Statusbeschreibung"
          dot2Title="Statusbeschreibung"
          statusDotConfig1={dotStates[4]}
          statusDotConfig2={dotStates[5]}
        />
        <StatusBox
          title="Finishing"
          dot1Title="Statusbeschreibung"
          dot2Title="Statusbeschreibung"
          statusDotConfig1={dotStates[6]}
          statusDotConfig2={dotStates[7]}
        />
      </HorizontalGroup>
    </VerticalGroup>
  );
};

const getStyles = stylesFactory(() => {
  return {
    title: css`
      margin: 20px;
    `,
    box: css`
      background: #303030;
      width: 200px;
      height: 320px;
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    `,
    statusBox: css`
      width: 75px;
      height: 75px;
      background: #1a1a1a;
      border: 1px solid #4d4c4c;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    devText: css`
      height: 100px;
      overflow: hidden;
      margin-bottom: 15px;
      word-break: break-all;
    `,
  };
});
