import { PanelPlugin } from '@grafana/data';
import { ManufacturingPanel } from './ManufacturingPanel';
import { ManufacturingOptions, Component } from './types';

type ComponentOption = {
  value: Component;
  label: string;
};

const chunkOptions: ComponentOption[] = [
  {
    value: Component.Part1,
    label: Component.Part1.toString(),
  },
  {
    value: Component.Part2,
    label: Component.Part2.toString(),
  },
  {
    value: Component.Part3,
    label: Component.Part3.toString(),
  },
];

export const plugin = new PanelPlugin<ManufacturingOptions>(ManufacturingPanel).setPanelOptions((builder) => {
  return builder
    .addSelect({
      path: 'component',
      defaultValue: Component.Part1.toString(),
      name: 'Bauteil',
      settings: {
        options: chunkOptions,
      },
    })
    .addTextInput({
      path: 'title',
      name: 'Titel',
      description: 'Titel',
      defaultValue: 'Additive Fertigung',
    })
    .addTextInput({
      path: 'text1',
      name: 'Beschreibungstext 1',
      description: 'Entwicklung Beschreibungstext 1',
      defaultValue: '',
    })
    .addTextInput({
      path: 'text2',
      name: 'Beschreibungstext 2',
      description: 'Entwicklung Beschreibungstext 2',
      defaultValue: '',
    })
    .addFieldNamePicker({
      path: 'dot1StatusQueryName',
      name: 'Wert Pre 1 (QueryName)',
      description: 'Name der Query',
      category: ['Pre'],
    })
    .addTextInput({
      path: 'dot1Title',
      name: 'Titel im Popover',
      description: 'Titel Pre 1im Popover',
      defaultValue: '',
      category: ['Pre'],
    })
    .addTextInput({
      path: 'dot1Description',
      name: 'Info-Text im Popover',
      description: 'Text Pre 1 im Popover',
      defaultValue: '',
      category: ['Pre'],
    })
    .addFieldNamePicker({
      path: 'dot2StatusQueryName',
      name: 'Wert Pre 2 (QueryName)',
      description: 'Name der Query',
      category: ['Pre'],
    })
    .addTextInput({
      path: 'dot2Title',
      description: 'Titel Pre 2 im Popover',
      name: 'Titel im Popover',
      defaultValue: '',
      category: ['Pre'],
    })
    .addTextInput({
      path: 'dot2Description',
      name: 'Info-Text im Popover',
      description: 'Text Pre 2 im Popover',
      defaultValue: '',
      category: ['Pre'],
    })
    .addFieldNamePicker({
      path: 'dot3StatusQueryName',
      name: 'Wert In 1 (QueryName)',
      description: 'Name der Query',
      category: ['In'],
    })
    .addTextInput({
      path: 'dot3Title',
      name: 'Titel im Popover',
      description: 'Titel In 1 im Popover',
      defaultValue: '',
      category: ['In'],
    })
    .addTextInput({
      path: 'dot3Description',
      name: 'Info-Text im Popover',
      description: 'Text In 1 im Popover',
      defaultValue: '',
      category: ['In'],
    })
    .addFieldNamePicker({
      path: 'dot4StatusQueryName',
      name: 'Wert In 2 (QueryName)',
      description: 'Name der Query',
      category: ['In'],
    })
    .addTextInput({
      path: 'dot4Title',
      name: 'Titel im Popover',
      description: 'Titel In 2 im Popover',
      defaultValue: '',
      category: ['In'],
    })
    .addTextInput({
      path: 'dot4Description',
      name: 'Info-Text im Popover',
      description: 'Text In 2 im Popover',
      defaultValue: '',
      category: ['In'],
    })
    .addFieldNamePicker({
      path: 'dot5StatusQueryName',
      name: 'Wert Post 1 (QueryName)',
      description: 'Name der Query',
      category: ['Post'],
    })
    .addTextInput({
      path: 'dot5Title',
      name: 'Titel im Popover',
      description: 'Titel Post 1 im Popover',
      defaultValue: '',
      category: ['Post'],
    })
    .addTextInput({
      path: 'dot5Description',
      name: 'Info-Text im Popover',
      description: 'Text Post 1 im Popover',
      defaultValue: '',
      category: ['Post'],
    })
    .addFieldNamePicker({
      path: 'dot6StatusQueryName',
      name: 'Wert Post 2 (QueryName)',
      description: 'Name der Query',
      category: ['Post'],
    })
    .addTextInput({
      path: 'dot6Title',
      name: 'Titel im Popover',
      description: 'Titel Post 2 im Popover',
      defaultValue: '',
      category: ['Post'],
    })
    .addTextInput({
      path: 'dot6Description',
      name: 'Info-Text im Popover',
      description: 'Text Post 2 im Popover',
      defaultValue: '',
      category: ['Post'],
    })
    .addFieldNamePicker({
      path: 'dot7StatusQueryName',
      name: 'Wert Finishing 1 (QueryName)',
      description: 'Name der Query',
      category: ['Finishing'],
    })
    .addTextInput({
      path: 'dot7Title',
      name: 'Titel im Popover',
      description: 'Titel Finishing 1 im Popover',
      defaultValue: '',
      category: ['Finishing'],
    })
    .addTextInput({
      path: 'dot7Description',
      name: 'Info-Text im Popover',
      description: 'Text Finishing 1 im Popover',
      defaultValue: '',
      category: ['Finishing'],
    })
    .addFieldNamePicker({
      path: 'dot8StatusQueryName',
      name: 'Wert Finishing 2 (QueryName)',
      description: 'Name der Query',
      category: ['Finishing'],
    })
    .addTextInput({
      path: 'dot8Title',
      name: 'Titel im Popover',
      description: 'Titel Finishing 2 im Popover',
      defaultValue: '',
      category: ['Finishing'],
    })
    .addTextInput({
      path: 'dot8Description',
      name: 'Info-Text im Popover',
      description: 'Text Finishing 2 im Popover',
      defaultValue: '',
      category: ['Finishing'],
    });
});
